html, body {
  height: 100%;
  padding: 0;
  margin: 0; }

body {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; }

.center-message {
  margin: 0 auto; }
